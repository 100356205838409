import { useMutation } from '@tanstack/react-query'
import classnames from 'classnames'
import dynamic from 'next/dynamic'
import { CSSProperties, ReactNode } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'

import {
  UnlikelyCustomAttributes,
  UnlikelyMoney,
} from '@unlikelystudio/commerce-connector'
import { useAddCartLines } from '@unlikelystudio/react-ecommerce-hooks'

import { serializeProductsToAddAttributes } from '~/components/AddToCart/utils/serialize-product-add-to-cart-attributes'
import CtaWithPrice, { CtaWithPriceProps } from '~/components/CtaWithPrice'
import { useToast } from '~/components/Toast'
import BlurredCta from '~/components/UI/BlurredCta'

import { usePanel } from '~/providers/PanelProvider'

import { useUpdateCartAttributeProductTracking } from '~/hooks/cart/useUpdateCartAttributeProductTracking'

import { CART, CTA } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

enum ProductState {
  Available = 'available',
  PreOrder = 'preOrder',
  OutOfStock = 'outOfStock',
  StockAlert = 'stockAlert',
}

interface ProductToAdd {
  id: string
  attributes?: UnlikelyCustomAttributes
}

interface BundleToAdd {
  merchandiseId: string
  attributes?: UnlikelyCustomAttributes
  customizations?: ProductToAdd[]
  labelCustomization?: ProductToAdd
}

const PanelCart = dynamic(() => import('~/components/Panels/CartPanelWrapped'))

export interface AddToCartProps {
  className?: string
  children?: ReactNode | ReactNode[]
  isSimpleProductPreOrder?: boolean
  upsale?: ProductToAdd
  merchandiseId: string
  bundles?: BundleToAdd[]
  rootMultiBundle?: ProductToAdd
  customAttributes?: UnlikelyCustomAttributes
  price: UnlikelyMoney
  isFullscreen?: boolean
  availableForSale?: boolean
  disabled?: boolean
  style?: CSSProperties
  loading?: boolean
  onSuccess?: () => void
  onClick?: () => void
  withStockAlert?: boolean
  theme?: CtaWithPriceProps['theme']
  customizationImages?: string[]
}

export interface UseAddToCartProps {
  withToast?: boolean
  onSuccess?: AddToCartProps['onSuccess']
}

export function useAddToCart({ withToast, onSuccess }: UseAddToCartProps) {
  const triggerToast = useToast()
  const t = useTranslate()
  const { add: addPanel } = usePanel()
  const { updateCartAttributeProductTracking } =
    useUpdateCartAttributeProductTracking()
  return useAddCartLines({
    onSuccess: ({ cart }, updatedItems, context) => {
      updateCartAttributeProductTracking(cart)
      onSuccess && onSuccess()
      if (withToast) {
        triggerToast({
          uid: 'add-to-cart',
          children: (
            <>
              {t(CART.PRODUCT_ADDED)}
              <BlurredCta
                className={css.cta}
                theme={GlobalThemeColors.Black}
                onClick={() =>
                  addPanel({
                    component: <PanelCart />,
                  })
                }>
                {t(CART.ORDER)}
              </BlurredCta>
            </>
          ),
        })
      } else {
        addPanel({
          component: <PanelCart />,
        })
      }
    },
  })
}

function AddToCart({
  className,
  upsale,
  price,
  isFullscreen,
  style,
  availableForSale,
  disabled,
  bundles,
  rootMultiBundle,
  children,
  onSuccess,
  onClick,
  withStockAlert,
  isSimpleProductPreOrder,
  theme,
  loading,
  customizationImages,
}: AddToCartProps) {
  const bundleProductPreOrder =
    bundles?.[0]?.customizations?.[0]?.attributes?.find(
      (attribute) => attribute.key === '_isBundleProductPreOrder',
    )?.value

  const isBundleProductPreOrder = bundleProductPreOrder === 'true'

  const { mutate, isLoading } = useAddToCart({
    withToast: false,
    onSuccess,
  })

  const t = useTranslate()

  const processProductState = () => {
    if (availableForSale) {
      if (isSimpleProductPreOrder || isBundleProductPreOrder) {
        return ProductState.PreOrder
      }
      return ProductState.Available
    } else {
      if (withStockAlert) {
        return ProductState.StockAlert
      }
      return ProductState.OutOfStock
    }
  }

  const {
    mutateAsync: serializeProductsAttributes,
    isLoading: isSerializingProductsAttributes,
  } = useMutation(async () => {
    return await serializeProductsToAddAttributes({
      rootMultiBundle,
      upsale,
      bundles,
    })
  })

  const productState = processProductState()
  return (
    <CtaWithPrice
      theme={theme}
      disabled={productState === ProductState.OutOfStock}
      className={cx(className, isLoading)}
      isLoading={isLoading || loading || isSerializingProductsAttributes}
      style={style}
      isFullscreen={isFullscreen}
      onClick={async () => {
        onClick?.()
        if (!withStockAlert && availableForSale) {
          const productsToAdd = await serializeProductsAttributes()
          mutate(productsToAdd)
        }
      }}
      price={availableForSale && !disabled ? price : null}>
      {(productState === ProductState.Available || loading) &&
        (children ?? t(CTA.ADD_TO_CART))}
      {productState === ProductState.OutOfStock && t(CTA.OUT_OF_STOCK)}
      {productState === ProductState.PreOrder && t(CTA.PRE_ORDER)}
      {productState === ProductState.StockAlert && t(CTA.STOCK_ALERT)}
    </CtaWithPrice>
  )
}

AddToCart.defaultProps = {}

export default AddToCart
