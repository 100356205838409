import { postAkairosImagePreview } from '~/lib/handle-api'

import { removeBlankAttributes } from '~/utils/remove-blank-attributes'

async function replaceLabelBase64WithHash(attributes) {
  const base64Attribute = attributes?.find(
    (attr) => attr.key === '_labelImagebase64',
  )

  if (!base64Attribute) {
    return attributes
  }

  const hash = await postAkairosImagePreview({
    payload: {
      fileContent: base64Attribute.value,
    },
  })

  if (hash.success) {
    const newAttributes = attributes.filter(
      (attr) => attr.key !== '_labelImagebase64',
    )

    newAttributes.push({
      key: '_imageHash',
      value: hash.data.FileHash,
    })
    return newAttributes
  }

  return attributes
}

export async function serializeProductsToAddAttributes({
  rootMultiBundle,
  upsale,
  bundles,
}) {
  const rootMultiBundleToAdd = rootMultiBundle
    ? {
        ...rootMultiBundle,
        quantity: 1,
      }
    : []

  const upsalesToAdd = upsale
    ? [
        {
          variantId: upsale.id,
          quantity: 1,
          ...(upsale.attributes
            ? {
                attributes: upsale.attributes.filter(
                  (attr) => attr.value !== '',
                ),
              }
            : {}),
        },
      ]
    : []

  const bundlesToAdd = bundles
    ? (
        await Promise.all(
          bundles.map(async (bundle) => {
            const mainProductAttributes = await replaceLabelBase64WithHash(
              bundle?.attributes,
            )
            const mainProduct = {
              merchandiseId: bundle.merchandiseId,
              quantity: 1,
              attributes: removeBlankAttributes(mainProductAttributes),
            }

            const label = bundle?.labelCustomization
            const customizations = bundle?.customizations
            const labelAttributes = await replaceLabelBase64WithHash(
              label?.attributes,
            )
            const labelCustomizationToAdd = label
              ? {
                  merchandiseId: label.id,
                  quantity: 1,
                  ...(label.attributes
                    ? { attributes: removeBlankAttributes(labelAttributes) }
                    : {}),
                }
              : null

            const customizationsToAdd = customizations
              ? customizations.map((customization) => ({
                  merchandiseId: customization?.id,
                  quantity: 1,
                  ...(customization.attributes
                    ? {
                        attributes: removeBlankAttributes(
                          customization.attributes,
                        ),
                      }
                    : {}),
                }))
              : []

            return [
              mainProduct,
              labelCustomizationToAdd,
              ...customizationsToAdd,
            ].filter(Boolean)
          }),
        )
      ).flat()
    : []

  return []
    .concat(rootMultiBundleToAdd)
    .concat(upsalesToAdd)
    .concat(bundlesToAdd)
}
